var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('ocorrencia-entidade-component',{ref:"ocorrenciaHistorico",attrs:{"propEntidadeId":_vm.financeiroParcelaId,"propEntidadeNome":"financeiro_parcela"}}),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.totals.por_mensagem)?_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por mensagem","prop-items":_vm.totals.por_mensagem,"propShowCheckbox":false},scopedSlots:_vm._u([{key:"item.Ação",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary",attrs:{"x-small":""},on:{"click":function($event){return _vm.selecionar(item)}}},[_vm._v(" Selecionar ")])]}},{key:"item.temp_envio_manual",fn:function(ref){
var item = ref.item;
return [(item.valor_envio_manual)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])]}},{key:"item.temp_envio_automatico",fn:function(ref){
var item = ref.item;
return [(item.valor_envio_automatico)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])]}}],null,true)}):_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1),(_vm.totals.por_mensagem)?_c('v-row',[_c('v-col',[_c('imprimir-tabela-component',{staticStyle:{"float":"left"},attrs:{"propRef":[_vm.tabelaRef],"propDashboardFinanceiro":true}})],1)],1):_vm._e(),(_vm.totals.por_mensagem)?_c('v-row',[_c('v-col',[_c('v-data-table',{ref:"tabela",attrs:{"headers":_vm.getHeaders,"items":_vm.items,"footer-props":{itemsPerPageOptions: [-1]},"hide-default-footer":"","dense":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.aluno_nome",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":item.aluno_nome,"propObjectId":item.aluno_id,"propToolName":"Aluno"}})]}},{key:"item.aluno_celular",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPhone")(value))+" ")]}},{key:"item.responsavel_celular",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPhone")(value))+" ")]}},{key:"item.responsavel_nome",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):_c('span',[_vm._v(" O prório aluno ")])]}},{key:"item.vencimento",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.valor_atualizado",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatPriceValue")(value))+" ")]}},{key:"item.parcela_numero",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.parcela_numero)+"/"+_vm._s(item.financeiro_numero_parcelas)+" ")]}},{key:"item.observacao",fn:function(ref){
var item = ref.item;
return [_c('EnviarWhatsButton',{attrs:{"propFinanceiroParcelaId":item.id,"propSyngooMensagemId":Number(_vm.syngooMensagemId),"propUnidadeId":Number(item.unidade_id),"propUnidadeResponsavelFinanceiroCelular":Number(item.unidade_responsavel_financeiro_celular),"propAlunoResponsavelFinanceiro":Number(item.aluno_responsavel_financeiro),"propAlunoCelular":Number(item.aluno_celular),"propResponsavelCelular":Number(item.responsavel_celular),"propEnvioManual":_vm.envioManual,"propEnvioAutomatico":_vm.envioAutomatico,"propSyngooConfiguracaoStatus":_vm.syngooConfiguracaoStatus,"propOcorrenciaId":item.ocorrencia_id}}),_c('v-btn',{staticClass:"ma-2",attrs:{"x-small":""},on:{"click":function($event){return _vm.showHistoricoOcorrencias(item.id)}}},[_vm._v(" ocorrências ")])]}}],null,true)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }