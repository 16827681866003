import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { analyticsStore, ocorrenciaStore, syngooMensagemConfiguracaoStore, mainStore, syngooConfiguracaoStore, } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import SyngooFaleConosco from '@/components/SyngooFaleConosco.vue';
import OcorrenciaEntidadeComponent from '@/components/OcorrenciaEntidadeComponent.vue';
import EnviarWhatsButton from '@/components/EnviarWhatsButton.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
let EnvioDeMensagem = class EnvioDeMensagem extends Vue {
    constructor() {
        super(...arguments);
        this.items = [];
        this.selectPeriodDate = null;
        this.financeiroParcelaId = null;
        this.showOcorrencia = false;
        this.syngooMensagemId = null;
        this.envioManual = null;
        this.envioAutomatico = null;
        this.syngooConfiguracaoStatus = null;
        this.tabelaRef = null;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get getHeaders() {
        let header = [];
        if (this.userProfile.company.escola_id == 3) {
            header = [
                {
                    text: 'Unidade',
                    value: 'unidade_nome_fantasia',
                    table: 'unidade',
                    show: true,
                    type: 'str',
                },
            ];
        }
        else {
            header = [
                {
                    text: 'Aluno - Nome',
                    value: 'aluno_nome',
                    table: 'aluno',
                    show: true,
                    type: 'str',
                },
                {
                    text: 'Aluno - Celular',
                    value: 'aluno_celular',
                    table: 'aluno',
                    show: true,
                    type: 'str',
                },
                {
                    text: 'Responsável - Nome',
                    value: 'responsavel_nome',
                    table: 'aluno',
                    show: true,
                    type: 'str',
                },
                {
                    text: 'Responsável - Celular',
                    value: 'responsavel_celular',
                    table: 'aluno',
                    show: true,
                    type: 'str',
                },
            ];
        }
        return [
            ...header,
            {
                text: 'Parcela',
                value: 'parcela_numero',
                table: 'financeiro_parcela',
                show: true,
                type: '',
            },
            {
                text: 'Categoria',
                value: 'financeiro_categoria_nome',
                table: 'financeiro_parcela',
                show: true,
                type: '',
            },
            {
                text: 'Observação',
                value: 'financeiro_observacao',
                table: 'financeiro',
                show: true,
                type: '',
            },
            {
                text: 'Vencimento',
                value: 'vencimento',
                table: 'financeiro_parcela',
                show: true,
                type: 'date',
            },
            {
                text: 'Valor Atualizado',
                value: 'valor_atualizado',
                table: 'tmp',
                show: true,
            },
            {
                text: 'Observação',
                table: 'ocorrencia',
                value: 'observacao',
                type: 'str',
                show: true,
            },
        ];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    getSemCelular(item) {
        if (item.unidade_id) {
            // drive
            if (item.unidade_responsavel_financeiro_celular) {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if (item.aluno_responsavel_financeiro) {
                // escola e aluno responsavel financeiro
                if (item.aluno_celular) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                // escola e aluno nao responsavel financeiro
                if (item.responsavel_celular) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
    }
    async abrirNoWhats(item) {
        // @ts-ignore
        const envio = await syngooMensagemConfiguracaoStore.SyngooEnviaWhats({
            id: item.id,
            syngoo_mensagem_id: this.syngooMensagemId,
        });
        item.ocorrencia_id = 'enviado';
        window.open(`https://wa.me/+55${envio.phone}?text=${envio.message}`, '_blank');
        item.ocorrencia_id = 'enviado';
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'ocorrencia.created_at': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await ocorrenciaStore.getOcorrenciasQuery(queryIn);
    }
    async selecionar(item) {
        this.items =
            await syngooMensagemConfiguracaoStore.getSyngooMensagemConfiguracaoListaEnvioMensagem(item.id);
        this.syngooMensagemId = item.id;
        this.envioManual = item.valor_envio_manual;
        this.envioAutomatico = item.valor_envio_automatico;
        this.tabelaRef = this.$refs.tabela;
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsSyngooEnvioDeMensagem();
    }
    showHistoricoOcorrencias(id) {
        this.showOcorrencia = false;
        this.showOcorrencia = true;
        this.financeiroParcelaId = id;
        this.$nextTick(() => {
            const form = this.$refs.ocorrenciaHistorico;
            form.show();
        });
    }
    async enviar(item) {
        // @ts-ignore
        await syngooMensagemConfiguracaoStore.SyngooEnviaWhats({
            id: item.id,
            syngoo_mensagem_id: this.syngooMensagemId,
        });
        item.ocorrencia_id = 'enviado';
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(ocorrenciaStore.getOcorrenciasQuery);
        // analyticsStore.setHeaders(this.headers);
        await this.doSearchTotals();
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
        this.syngooConfiguracaoStatus =
            await syngooConfiguracaoStore.getSyngooConfiguracaoStatus();
    }
};
EnvioDeMensagem = __decorate([
    Component({
        components: {
            GenericListItemsCardComponent,
            GenericListTotalCardComponent,
            OcorrenciaEntidadeComponent,
            EnviarWhatsButton,
            OpenNewTabComponent,
            PeriodSelectorComponent,
            SyngooFaleConosco,
            TextExpandableComponent,
            ImprimirTabelaComponent,
        },
    })
], EnvioDeMensagem);
export default EnvioDeMensagem;
